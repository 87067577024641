// ----------------------------------------------------------------
/* eslint-disable no-unused-vars */
// ----------------------------------------------------------------
/* eslint-disable react-hooks/exhaustive-deps */
// ----------------------------------------------------------------
import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Button, Tooltip } from '@mui/material'
import AccordionComponent from '../../components/LocalElements/Accordion/Accordion'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import PencilIcon from '../../../img/icon/PencilIcon'
import DeleteIcon from '../../../img/icon/DeleteIcon'
import FilterCompany from '../../components/LocalElements/Filter/FilterCompany'
import SearchQuery from '../../components/LocalElements/Search/SearchQuery'
import R from '../../../services/app/request.service'
import Table from '../../components/LocalElements/Table/Table'
import { setSearchQuery as setSearchQueryCases } from '../../../store/slices/app/views/cases'
import { refreshMasterArshin, refreshCases } from '../../../store/slices/app/controlers/updater'
import useWindowDimensions from '../../hooks/useWindowDimensions'
import { setName } from '../../../store/slices/app/controlers/pageName'
import BasicTabs from '../../components/LocalElements/TabsMenu/TabsMenu'
import UserIcon from '../../../img/icon/User'
import StarIcon from '../../../img/icon/StarIcon'
import StarIconColor from '../../../img/icon/StarIconColor'
import StarDisabledIcon from '../../../img/icon/StarDisabledIcon'
import {
  setOpen,
  setTitle,
  setChildren,
  setConfirmAction,
  setBA,
  resetState,
} from '../../../store/slices/app/comps/modal'
import {
  setOpen as setOpenAlert,
  setMessage,
  setType,
} from '../../../store/slices/app/comps/snackbar'

const Cases = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const isToggle = useSelector(state => state.toggleReducer.isToggle)
  const { width } = useWindowDimensions()

  const userRole = useSelector(state => state.headerReducer.role)

  const data = useSelector(state => state.casesReducer.casesList)
  const masterArshinData = useSelector(state => state.masterArshinReducer.masterArshinList)
  const casesSearchQuery = useSelector(state => state.casesReducer.searchQuery)
  const companyIdSuperAdmin = useSelector(state => state.companyIdReducer.companyId)
  const companyIdUser = useSelector(state => state.headerReducer.companyId)
  const companyId = userRole === 'super' ? companyIdSuperAdmin : companyIdUser
  const [viewVariant, setViewVariant] = useState(0)

  const changeSearchQuery = param => {
    dispatch(setSearchQueryCases(param))
  }

  const changeViewVariant = param => {
    setViewVariant(param)
    localStorage.setItem('viewVariant', param)
  }

  const handleCasesAdd = () => {
    navigate('/metriva/cases/create')
  }
  const handleCasesOfficialUserAdd = () => {
    navigate('/metriva/cases/create_official_user')
  }
  const handleRowClick = param => {
    navigate(`/metriva/cases/${param.row.id}`)
  }
  const handleEditCase = caseId => {
    navigate(`/metriva/cases/${caseId}/edit`)
  }
  const handleEditMaster = masterId => {
    navigate(`/metriva/cases/${masterId}/edit_master`)
  }

  const handleMainCase = async caseId => {
    const { status } = await R.mainCases(caseId)
    if (status === 200) {
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Статус комплекта изменен'))
      dispatch(setOpen(false))
      dispatch(refreshCases())
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Ошибка обращения к API.'))
    }
  }

  const handleDelete = async caseId => {
    const { status } = await R.deleteCase(caseId)
    if (status === 200) {
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Комплект СП удален'))
      dispatch(setOpen(false))
      dispatch(refreshCases())
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Ошибка обращения к API.'))
    }
  }

  const handleDeleteMaster = async masterId => {
    const { status } = await R.deleteMaster(masterId)
    if (status === 200) {
      dispatch(setType('success'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Мастер удален'))
      dispatch(setOpen(false))
      dispatch(refreshMasterArshin())
      dispatch(resetState())
    } else {
      dispatch(setType('error'))
      dispatch(setOpenAlert(true))
      dispatch(setMessage('Ошибка обращения к API.'))
    }
  }

  let colData = [
    { field: 'innerNumber', headerName: 'Внутренний номер СП', flex: 1 },
    {
      field: 'master',
      headerName: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          Официальный пользователь
          {(userRole === 'metrologist' || userRole === 'brigadier') && (
            <Tooltip
              title={
                'Сотрудник, который обладает квалификацией для проведения поверочных работ в рамках определённых областей измерения. Информация о нём передается в ФГИС Аршин и ФСА, а также используется для создания и заполнения отчётных журналов.'
              }
              placement='top'
              style={{ marginLeft: '7px' }}
            >
              <i
                className='fas fa-question-circle question'
                style={{ marginLeft: '7px', cursor: 'pointer' }}
              />
            </Tooltip>
          )}
        </div>
      ),
      flex: 1,
    },
    { field: 'measurement', headerName: 'Область измерения', flex: 1 },
    { field: 'methods', headerName: 'Методика поверки', flex: 1 },
    { field: 'typeMethods', headerName: 'Тип поверки', flex: 1 },
  ]

  if (userRole === 'brigadier' || userRole === 'metrologist') {
    colData.splice(6, 0, {
      field: 'status',
      headerName: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          Основной комплект
          <Tooltip
            title='Данные основного комплекта СП будут использованы для автозаполнения полей в момент создания нового протокола'
            placement='top'
            style={{ marginLeft: '7px' }}
          >
            <i
              className='fas fa-question-circle question'
              style={{ marginLeft: '7px', cursor: 'pointer' }}
            />
          </Tooltip>
        </div>
      ),
      flex: 1,
      renderCell: params => {
        const { status, id } = params.row
        const handleClick = async event => {
          event.stopPropagation()
          await handleMainCase(id)
        }
        if (status === 'main') {
          return (
            <Tooltip title='Отменить назначение' placement='top-start'>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <StarIconColor onClick={handleClick} style={{ cursor: 'pointer' }} />
              </div>
            </Tooltip>
          )
        } else if (status === 'not-main') {
          return (
            <Tooltip title='Назначить основным комплектом СП' placement='top-start'>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <StarIcon onClick={handleClick} style={{ cursor: 'pointer' }} />
              </div>
            </Tooltip>
          )
        } else {
          const handleDisabledIconClick = event => {
            event.stopPropagation()
          }
          return (
            <Tooltip
              title='Основной комплект СП уже назначен, для его изменения отмените текущее назначение'
              placement='top-start'
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <StarDisabledIcon onClick={handleDisabledIconClick} style={{ cursor: 'default' }} />
              </div>
            </Tooltip>
          )
        }
      },
    })
  }

  if (userRole === 'super' || userRole === 'admin') {
    colData.splice(6, 0, {
      field: 'actions',
      type: 'actions',
      headerName: 'Действия',
      width: 200,
      getActions: item => {
        const localItem = item

        return [
          <div
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '20px',
            }}
          >
            <Tooltip title='Редактировать Комплект СП' placement='top-end'>
              <span onClick={() => handleEditCase(localItem.id)} className='action-container-block'>
                <span
                  className='fas fa-pencil-alt icon'
                  style={{
                    fontSize: '15px',
                    marginTop: '0px',
                  }}
                />
              </span>
            </Tooltip>

            <Tooltip title='Удалить Комплект СП' placement='top-end'>
              <span
                onClick={() => {
                  dispatch(setOpen(true))
                  dispatch(setTitle('Вы действительно хотите удалить комплект СП?'))
                  dispatch(setChildren('Отменить действие будет уже невозможно.'))
                  dispatch(setConfirmAction(() => handleDelete(localItem.id)))
                }}
                style={{
                  width: '5%',
                  display: 'flex',
                  flexFlow: 'row',
                  justifyContent: 'space-around',
                  cursor: 'pointer',
                }}
              >
                <span
                  className='fas fa-trash icon'
                  style={{
                    fontSize: '15px',
                  }}
                />
              </span>
            </Tooltip>
          </div>,
        ]
      },
    })
  }

  useEffect(() => {
    dispatch(setName('Средства проверки'))
  }, [])

  const noRowsText =
    viewVariant === 0
      ? 'Данные о доступных комплектах СП отсутствуют'
      : 'Данные об официальных пользователях СП отсутствуют'

  return (
    <main className={isToggle ? 'main_open' : 'main'}>
      {userRole === 'super' && (
        <div
          style={{
            width: width > 1050 ? '605px' : '100%',
          }}
        >
          <FilterCompany />
        </div>
      )}

      {(userRole === 'super' || userRole === 'admin') && (
        <div style={{ margin: userRole === 'super' ? '10px 0 12px' : '0px 0 12px' }}>
          <BasicTabs
            viewVariant={viewVariant}
            callback={changeViewVariant}
            tabs={[{ label: 'Комплекты СП' }, { label: 'Официальные пользователи СП' }]}
          />
        </div>
      )}

      {viewVariant === 0 ? (
        <React.Fragment>
          <div className='header_box' style={{ marginBottom: '0px' }}>
            {true ? (
              <div
                style={{
                  width: width > 1050 ? '18%' : '100%',
                }}
              >
                <Button
                  variant='contained'
                  disabled={
                    (userRole === 'super' && companyId) || userRole === 'admin' ? false : true
                  }
                  onClick={handleCasesAdd}
                  sx={{ mt: 1, mr: 1 }}
                  style={{
                    textTransform: 'none',
                    fontSize: '15px',
                    fontFamily: '"Wix Madefor Display", sans-serif',
                    padding: '0px 20px',
                    height: '55px',
                    margin: 0,
                    borderRadius: '12px',
                    width: '100%',
                  }}
                >
                  Добавить комплект
                </Button>
              </div>
            ) : null}

            <div style={{ width: '82%' }}>
              <SearchQuery searchQuery={casesSearchQuery} setSearchQuery={changeSearchQuery} />
            </div>
            <div
              style={{
                width: width > 1050 ? '16%' : '100%',
                position: 'relative',
              }}
            >
              <Button
                variant='outlined'
                disabled={true}
                onClick={() => {}}
                startIcon={<FilterAltIcon />}
                style={{
                  width: '100%',
                  textTransform: 'none',
                  fontSize: '15px',
                  fontFamily: '"Wix Madefor Display", sans-serif',
                  padding: '0px 20px',
                  borderRadius: '12px',
                  height: '54.4px',
                }}
              >
                Фильтры
              </Button>
            </div>
          </div>

          {!companyId ? (
            <div
              className='page_null'
              style={{
                borderRadius: '12px',
                background: 'white',
                marginTop: '12px',
              }}
            >
              <h4
                style={
                  width > 1050
                    ? {
                        fontSize: '20.8px',
                        letterSpacing: '0.4px',
                        color: '#132532',
                        fontWeight: '400',
                      }
                    : {
                        fontSize: '17px',
                        letterSpacing: '0.4px',
                        color: '#132532',
                        fontWeight: '400',
                        textAlign: 'center',
                      }
                }
              >
                Для просмотра данных выберите компанию
              </h4>
            </div>
          ) : (
            <Table
              css={{
                marginTop: '12px',
                backgroundColor: '#ffffff',
                border: '1px solid #d7ddea',
                '& .MuiDataGrid-columnHeaderTitle': {
                  whiteSpace: 'normal',
                  lineHeight: '1.2',
                  wordWrap: 'break-word',
                },
                '& .MuiDataGrid-cell:hover': {
                  cursor: 'default',
                },
                '& .MuiDataGrid-cell': {
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',

                  height: 'auto',
                  lineHeight: '1.5',
                  padding: '8px',
                  boxSizing: 'border-box',
                },
              }}
              colData={colData}
              rowData={data.map(item => ({
                id: item?.id,
                innerNumber: item?.inner_name,
                master: `${item.arshinMaster?.surname} ${item.arshinMaster?.name} ${item.arshinMaster?.patr}`,
                measurement: item?.measurement?.name,
                methods: item?.method?.name,
                status: item?.is_main_status,
                typeMethods: item?.verificationType?.name,
              }))}
              rowCallback={handleRowClick}
              noRowsText={noRowsText}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
            />
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <AccordionComponent
            title='Краткое описание'
            dataType='custom'
            backgroundColor='white'
            data={
              <span style={{ lineHeight: '23px' }}>
                Официальные пользователи СП — это ответственные лица, которые обладают квалификацией
                для проведения поверочных работ в рамках определённых областей измерения. Информация
                о данных пользователях передаются в ФГИС Аршин и ФСА, а также используются для
                создания и заполнения отчётных журналов.
              </span>
            }
            isExpanded={true}
          />
          <div className='header_box' style={{ marginBottom: '12px', marginTop: '12px' }}>
            {true ? (
              <div
                style={{
                  width: width > 1050 ? '18%' : '100%',
                }}
              >
                <Button
                  variant='contained'
                  disabled={
                    (userRole === 'super' && companyId) || userRole === 'admin' ? false : true
                  }
                  onClick={handleCasesOfficialUserAdd}
                  sx={{ mt: 1, mr: 1 }}
                  style={{
                    textTransform: 'none',
                    fontSize: '15px',
                    fontFamily: '"Wix Madefor Display", sans-serif',
                    padding: '0px 20px',
                    height: '55px',
                    margin: 0,
                    borderRadius: '12px',
                    width: '100%',
                  }}
                >
                  Добавить пользователя
                </Button>
              </div>
            ) : null}

            <div style={{ width: '82%' }}>
              <SearchQuery searchQuery={casesSearchQuery} setSearchQuery={changeSearchQuery} />
            </div>
          </div>

          {!companyId ? (
            <div
              className='page_null'
              style={{
                borderRadius: '12px',
                background: 'white',
              }}
            >
              <h4
                style={
                  width > 1050
                    ? {
                        fontSize: '20.8px',
                        letterSpacing: '0.4px',
                        color: '#132532',
                        fontWeight: '400',
                      }
                    : {
                        fontSize: '17px',
                        letterSpacing: '0.4px',
                        color: '#132532',
                        fontWeight: '400',
                        textAlign: 'center',
                      }
                }
              >
                Для просмотра данных выберите компанию
              </h4>
            </div>
          ) : (
            <Table
              css={{
                marginTop: '12px',
                backgroundColor: '#ffffff',
                border: '1px solid #d7ddea',
                '& .MuiDataGrid-columnHeaderTitle': {
                  whiteSpace: 'normal',
                  lineHeight: '1.2',
                  wordWrap: 'break-word',
                },
                '& .MuiDataGrid-cell:hover': {
                  cursor: 'default',
                },
                '& .MuiDataGrid-cell': {
                  whiteSpace: 'normal',
                  wordWrap: 'break-word',

                  height: 'auto',
                  lineHeight: '1.5',
                  padding: '8px',
                  boxSizing: 'border-box',
                },
              }}
              colData={[
                {
                  field: 'name',
                  headerName: 'Ф.И.О.',
                  flex: 1,
                },
                {
                  field: 'role',
                  headerName: 'Должность в компании',
                  flex: 1,
                },
                {
                  field: 'attribution',
                  headerName: 'Приписанные комплекты, шт',
                  flex: 1,
                },
                {
                  field: 'measurement',
                  headerName: (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      Рабочая область измерения
                      <Tooltip
                        title={
                          'Область(и) измерения, в рамках которой(ых) пользователь имеет официальное право осуществлять поверочную деятельность'
                        }
                        placement='top'
                        style={{ marginLeft: '7px' }}
                      >
                        <i
                          className='fas fa-question-circle question'
                          style={{ marginLeft: '7px', cursor: 'pointer' }}
                        />
                      </Tooltip>
                    </div>
                  ),
                  flex: 1,
                },
                {
                  field: 'actions',
                  type: 'actions',
                  headerName: 'Действия',
                  width: 200,
                  getActions: item => {
                    const localItem = item

                    return [
                      <div
                        style={{
                          display: 'flex',
                          flexFlow: 'row',
                          justifyContent: 'space-between',
                          gap: '20px',
                        }}
                      >
                        <Tooltip title='Редактировать данные пользователя СП' placement='top-end'>
                          <span
                            onClick={() => handleEditMaster(localItem.id)}
                            className='action-container-block'
                          >
                            <span
                              className='fas fa-pencil-alt icon'
                              style={{
                                fontSize: '15px',
                                marginTop: '0px',
                              }}
                            />
                          </span>
                        </Tooltip>
                        {localItem.row.attribution === 0 ? (
                          <Tooltip title='Удалить пользователя СП' placement='top-end'>
                            <span
                              onClick={() => {
                                dispatch(setOpen(true))
                                dispatch(setTitle('Удаление официального пользователя СП'))
                                dispatch(
                                  setChildren(
                                    'Вы действительно хотите удалить выбранного пользователя СП? Обратите внимание, после удаления данные о нём будут стёрты из системы окончательно.',
                                  ),
                                )
                                dispatch(setBA('Удалить'))
                                dispatch(setConfirmAction(() => handleDeleteMaster(localItem.id)))
                              }}
                              style={{
                                width: '5%',
                                display: 'flex',
                                flexFlow: 'row',
                                justifyContent: 'space-around',
                                cursor: 'pointer',
                              }}
                            >
                              <span
                                className='fas fa-trash icon'
                                style={{
                                  fontSize: '15px',
                                }}
                              />
                            </span>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title='Удаление недоступно т.к. пользователь указан поверителем комплектов СП'
                            placement='top-end'
                          >
                            <span className='action-container-block-disabled'>
                              <span
                                className='fas fa-trash icon'
                                style={{
                                  fontSize: '15px',
                                  color: '#ccc',
                                }}
                              />
                            </span>
                          </Tooltip>
                        )}
                      </div>,
                    ]
                  },
                },
              ]}
              rowData={masterArshinData.map(item => ({
                id: item?.id,
                name: item?.fullname,
                role: item?.post,
                attribution: item?.cases_count,
                measurement: item?.measurements,
              }))}
              noRowsText={noRowsText}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
            />
          )}
        </React.Fragment>
      )}
    </main>
  )
}

export default Cases
